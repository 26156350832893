<template>
    <div class="pathology" v-if="!loading">
        <div class="back" @click="$router.push({ name : 'PathologyList'})"></div>
        <h1>{{ `${patient.firstname} ${patient.lastname}`}}</h1>
        <h2>{{ JSON.parse(pathology.name)[this.$store.state.LANG] }} - {{ JSON.parse(periodName)[this.$store.state.LANG] }}</h2>

        <template v-if="periodActive != 2">
            <div class="btn btn-M0" v-if="periodActive != 1" @click="goInjection">{{ $t('patient.displayInjection')}}</div>
            <PathologySubStep
                :steps="steps"
            >
            </PathologySubStep>
        
            <PathologyQuestionsForm
                :period="periodActive "
                :steps="steps"
            >
            </PathologyQuestionsForm>
        </template>
        <template v-else>
            <FormInjection></FormInjection>
        </template>
    </div>
</template>

<script>
import PathologySubStep from './../../components/patient/PathologySubStep'
import PathologyQuestionsForm from './../../components/forms/PathologyQuestionsForm'
import FormInjection from './../../components/forms/FormInjection'


import store from './../../store'
import axios from 'axios'

export default {
    name : 'CoordinatorMonitoring',
    components : {
        PathologySubStep,
        PathologyQuestionsForm,
        FormInjection
    },
    data() {
        return {
            steps : null,
            periodActive : null,
            pathologyStep : [],
            pathology : null,
            patient : null,
            loading : true,
            periodName : null
        }
    },
    methods : {
        switchPeriod(id) {
            this.periodActive = id
        },
        goInjection() {
            this.periodActive = 2
        }
 
    },
    watch : {
        periodActive() {
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .get(store.getters.getURL +"monitoring/get_steps/" +  this.periodActive, config)
                .then(function (response) {
                    if(response.status === 200) {
                        vm.steps = response.data.steps
                        //store.commit('setCurrentModule', response.data.steps[0])
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        }

    },
    mounted() {
        let config = {
            validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"monitoring/get_infos/" + this.$route.params.id, config)
            .then(function (response) {
                vm.loading = false
                vm.patient = response.data.user 
                vm.pathology = response.data.pathology
                vm.pathologyStep = response.data.periods
                vm.periodActive = response.data.pathology.current_period
                vm.periodName = vm.pathologyStep.find(e => e.id === vm.pathology.current_period).name
                store.commit('setCurrentModule', response.data.pathology.current_module)
                if(vm.$route.params.step && vm.$route.params.step == 2) {
                    vm.periodActive = 2
                }else {
                    vm.periodActive = response.data.pathology.current_period
                }
            

            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>

    .back {
        margin:0 auto 20px auto;
    }

    .pathology  {   
        background: #fff;
        border-radius:10px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        margin:80px auto ;
        padding: 20px 40px;
        width:$boxWidth;
        max-width: calc(90% - 80px);
        position:relative;
        min-height: calc(100vh - 351px);
    }

    h1 {
        text-align: center;
        margin-bottom: 10px;
        font-size: 25px;
        font-family: $fontBlack;
        letter-spacing: 1px;
        text-transform:uppercase;
    }

    h2 {
        text-align: center;
        font-size:25px;
        margin-bottom: 10px;
        color:$textGrey
    }

    .results-container{
        display: flex;
        justify-content: center;
        margin:40px auto;
    }

    form {
        display: flex;
        flex-direction: column;
        margin:40px auto;
        width:1000px;
        max-width: 90%;
    }

    .btn {
        width: 200px;
    }

    .submit-row {
        display: flex;
        justify-content: center;
        margin-top:20px;
    }

    .btn-M0 {
        margin: 20px auto;
        font-size:15px;
        width:300px;
        text-align: center;
    }

    @media  screen and (max-width:768px) {
        h1, h2 {
            font-size:18px;
        }

        .pathology {
            padding:20px 10px;
            max-width: calc(90% - 20px);
        }

        
    }
</style>